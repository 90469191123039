import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {rootReducer} from '../_reducers';
import quizStateSubscribers  from '../_subscribers';


export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

store.subscribe(quizStateSubscribers.handlerChangeForm.bind(undefined, store))
store.subscribe(quizStateSubscribers.handlerChangeDiscountIncreasing.bind(undefined, store))
store.subscribe(quizStateSubscribers.handlerChangeDiscountOneStep.bind(undefined, store))

