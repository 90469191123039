import React from "react";
import {render} from "react-dom";
import {Provider} from "react-redux";
import {store} from "./_services";
import App from "./App";
import 'core-js/stable';

async function init() {
    render(
        <Provider store={store}>
            <App/>
        </Provider>,
        document.getElementById('app')
    );
}
init();


