import React from "react";
import {Router, Route} from "react-router-dom";
import {history} from "./_services";
import Loading from "./Loading";
import DynamicImport from './DynamicImport'

const AgreementPage = (props) => (
    <DynamicImport load={() => import('./pages/AgreementPage')}>
        {(Component) => Component === null
            ? <Loading/>
            : <Component {...props} />}
    </DynamicImport>
)

const QuizPage = (props) => (
    <DynamicImport load={() => import('./pages/QuizPage')}>
        {(Component) => Component === null
            ? <Loading/>
            : <Component {...props} />}
    </DynamicImport>
)

const App = () => (
        <Router history={history}>
            <Route  exact path="/agreement" component={AgreementPage}/>
            <Route  exact path="/" component={QuizPage}/>
            <Route  path="/default" component={QuizPage}/>
            <Route  path="/new" component={QuizPage}/>
            <Route  path="/developer" component={QuizPage}/>
        </Router>
);

export default App;


