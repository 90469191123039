import { combineReducers } from 'redux';
import { quizStateReducer as quizState } from './quizState.reducer';
import { quizDataReducer as quizData } from './quizData.reducer';
import { flatsDataReducer as flatsData } from './flatsData.reducer';

export const rootReducer = combineReducers({
    quizState,
    quizData,
    flatsData
});

export type RootState = ReturnType<typeof rootReducer>