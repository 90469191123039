const check = (displayCondition: any, clientAnswer: any) => {
    if (displayCondition.condition.operation === 'eq') {
        if (clientAnswer && clientAnswer[displayCondition.condition.value])
            return true;
    } else if (displayCondition.condition.operation === 'like') {
        if (clientAnswer && clientAnswer['custom'].indexOf(displayCondition.condition.value) !== -1)
            return true;
    }
    return false;
}

export const checkConditionIsDone = (question: any, clientAnswers: []) => {
    if (!question.displayConditions || question.displayConditions.length === 0) return true;

    if (!question.typeIfFewConditions || question.typeIfFewConditions === 'and') {
        let r = true;
        question.displayConditions.forEach((displayCondition: any) => {
            if (check(displayCondition, clientAnswers[displayCondition.questionId]) === false) {
                r = false;
            }
        });
        return r;
    } else if (question.typeIfFewConditions === 'or') {
        let r = false;
        question.displayConditions.forEach((displayCondition: any) => {
            if (check(displayCondition, clientAnswers[displayCondition.questionId])) {
                r = true;
            }
        });
        return r;
    }
    return false;
}

// export const getMaxLengthQuestion = (questions: any) => {
//
//     let lengthResult = 0;
//     let variantClientAnswers = [[]];
//
//     questions.forEach((question: any, questionIndex: number) => {
//         variantClientAnswers.forEach((clientAnswers: any, clientAnswersIndex: number) => {
//             clientAnswers[question.id] = [];
//             if(question.displayConditions && question.displayConditions.length !== 0) {
//                 question.displayConditions.forEach((displayCondition: any) => {
//                     if (clientAnswers[displayCondition.questionId] && clientAnswers[displayCondition.questionId].length) {
//                         variantClientAnswers.push(clientAnswers);
//                     }
//                     if(displayCondition.condition.operation === 'eq'){
//                         clientAnswers[displayCondition.questionId][displayCondition.condition.value] = ' ';
//                     }else if(displayCondition.condition.operation === 'like'){
//                         clientAnswers[displayCondition.questionId]['custom'] = displayCondition.condition.value;
//                     }
//                 });
//             }
//         });
//
//     });
//
//     variantClientAnswers.forEach((clientAnswers: any, clientAnswersIndex: number) => {
//         let length = 0;
//         questions.forEach((question: any) => {
//             if (true === checkConditionIsDone(question, clientAnswers)) {
//                 length = length + 1;
//             }
//         });
//         if (length > lengthResult) {
//             lengthResult = length;
//         }
//     });
//
//     return lengthResult;
// }

export const questionsHasBranch = (questions: any): boolean => {

    for (let question of questions) {
        if (question.displayConditions && question.displayConditions.length !== 0) {
            return true;
        }
    }

    return false;
}