export const afterLoadQuiz  = function (quizData) {
    window.parent.postMessage({
        action: 'event',
        eventName: 'quizLoaded',
        isNewTheme: !!quizData.isNewTheme,
        quizId: quizData.id
    }, "*");

    if(quizData.info.analytic.ga.id){
        sendPostMessage('google', 'leadforms_open', quizData.id, quizData.info.analytic.ga.id)
    }

    if(quizData.info.analytic.metrika.id ){
        sendPostMessage('yandex', 'leadforms_open', quizData.id, quizData.info.analytic.metrika.id)
    }

    if(quizData.info.analytic.facebook){
        sendPostMessage('facebook', 'leadforms_open', quizData.id, quizData.info.analytic.facebook)
    }

    if(quizData.info.analytic.vk){
        sendPostMessage('vk', 'leadforms_open', quizData.id, quizData.info.analytic.vk)
    }

    if(quizData.info.analytic.roistat){
        sendPostMessage('roistat', 'leadforms_open', quizData.id, quizData.info.analytic.roistat)
    }
};

export const afterSendForm  = function (quizData) {
    if(quizData && quizData.info && quizData.info.analytic.ga.id){
        sendPostMessage('google', 'leadforms_finish', quizData.id, quizData.info.analytic.ga.id)
    }

    if(quizData && quizData.info && quizData.info.analytic.metrika.id){
        sendPostMessage('yandex', 'leadforms_finish', quizData.id, quizData.info.analytic.metrika.id)
    }

    if(quizData && quizData.info && quizData.info.analytic.vk){
        sendPostMessage('vk', 'leadforms_finish', quizData.id, quizData.info.analytic.vk)
    }

    if(quizData && quizData.info && quizData.info.analytic.facebook){
        sendPostMessage('facebook', 'leadforms_finish', quizData.id, quizData.info.analytic.facebook)
    }
}

export const afterStartQuiz  = function (quizData) {
    if(quizData && quizData.info && quizData.info.analytic.ga.id){
        sendPostMessage('google', 'leadforms_start', quizData.id, quizData.info.analytic.ga.id)
    }

    if(quizData && quizData.info && quizData.info.analytic.metrika.id){
        sendPostMessage('yandex', 'leadforms_start', quizData.id, quizData.info.analytic.metrika.id)
    }

    if(quizData && quizData.info && quizData.info.analytic.vk){
        sendPostMessage('vk', 'leadforms_start', quizData.id, quizData.info.analytic.vk)
    }

    if(quizData && quizData.info && quizData.info.analytic.facebook){
        sendPostMessage('facebook', 'leadforms_start', quizData.id, quizData.info.analytic.facebook)
    }
}

export const afterNextQuestion  = function (quizData, stepId) {
    if(quizData && quizData.info && quizData.info.analytic.ga.id){
        sendPostMessage('google', 'leadforms_step', quizData.id, quizData.info.analytic.ga.id, {stepId: stepId})
    }

    if(quizData && quizData.info && quizData.info.analytic.metrika.id){
        sendPostMessage('yandex', 'leadforms_step', quizData.id, quizData.info.analytic.metrika.id, {stepId: stepId})
    }

    if(quizData && quizData.info && quizData.info.analytic.vk){
        sendPostMessage('vk', 'leadforms_step', quizData.id, quizData.info.analytic.vk, {stepId: stepId})
    }

    if(quizData && quizData.info && quizData.info.analytic.facebook){
        sendPostMessage('facebook', 'leadforms_step', quizData.id, quizData.info.analytic.facebook, {stepId: stepId})
    }
}

function sendPostMessage(type, eventName, quizId, id, additional = {}) {
    window.postMessage({
        type: type,
        action: 'event',
        eventName: eventName,
        id: id,
        quizId: quizId,
        ...additional
    }, "*")
}