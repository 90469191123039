import React from "react";

export default class DynamicImport extends React.Component {
    state = {
        component: null
    }
    componentDidMount() {
        this.props.load()
            .then((component) => {
                this.setState({
                    component: this.props.component
                        ? component[this.props.component]
                        : component.default
                })
            })
    }
    render() {
        return this.props.children(this.state.component)
    }
}
