import {validateQuestion, validations} from "../_services";

const defaultState: QuizDataInterface = {
    isEmpty: true,
};

interface QuizDataInterface {
    isEmpty?: boolean,
    [x: string]: any
}

export const INIT_QUIZ_DATA = 'INIT_QUIZ_DATA';

export const quizDataReducer = (state: QuizDataInterface = defaultState, action: QuizDataActionTypes): QuizDataInterface => {
    switch (action.type) {
        case INIT_QUIZ_DATA:
            let data = validations(action.quizData, action.quizData.info.language ? action.quizData.info.language : 'russian');
            data.questions = data.questions.map((question: any) => {
                return validateQuestion(question, data.info.language ? data.info.language : 'russian');
            });
            return data;
        default:
            return state;
    }
};

interface SetQuizDataActionInterface {
    type: typeof INIT_QUIZ_DATA,
    quizData: QuizDataInterface
}

export function initQuizDataAction(quizState: QuizDataInterface): SetQuizDataActionInterface {
    return {
        type: INIT_QUIZ_DATA,
        quizData: quizState
    }
}



export type QuizDataActionTypes = SetQuizDataActionInterface;




