import {setQuizStateIem } from '../_actions';

export default {
    handlerChangeForm,
    handlerChangeDiscountIncreasing,
    handlerChangeDiscountOneStep
}




function handlerChangeForm(store) {

    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData



    if(quizState.loading) return;

    const questionActive = quizData.questions[quizState.activeKeyItem]

    const ifHasAnswers = questionActive && (questionActive.answers.length || (questionActive.other && questionActive.type == 'answers') || questionActive.type == 'input'|| questionActive.type == 'date')
        ? (!quizState.clientData.answers[questionActive.id] || (quizState.clientData.answers[questionActive.id] && !Object.values(quizState.clientData.answers[questionActive.id]).length))
        : false

    const isNoRequired = !! quizData.questions[quizState.activeKeyItem].isNoRequired;

    const logicNoAnswer =
        quizState.activeKeyItem >= 0
        && ifHasAnswers
        && !quizState.contacts
        && !isNoRequired;


    const form = {
        buttonPrevDisabled: (quizState.activeKeyItem > 0 || (quizState.contacts && quizData.questions.length) || quizState.finish) ? false : true,
        buttonNextDisabled: (quizState.activeKeyItem > quizData.questions.length - 1 || quizState.finish
            || logicNoAnswer
        ) ? true : false
    }

    if(form.buttonPrevDisabled != quizState.form.buttonPrevDisabled || form.buttonNextDisabled != quizState.form.buttonNextDisabled)
        store.dispatch(setQuizStateIem('form', form))


}

function handlerChangeDiscountIncreasing(store) {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData


    if(quizState.loading) return;
    if(!quizData.info.discount || !quizData.info.discount.value  ||  ['increasing', 'increasingPercent'].indexOf(quizData.info.discount.type) == -1) return;

    const discount = Math.round(quizData.info.discount.value * (quizState.activeKeyItem + 1 + ((quizState.contacts || quizState.finish) ? 1 : 0)) / (quizData.questions.length + 1))
    if (discount != quizState.discount)
        store.dispatch(setQuizStateIem('discount', discount))
}

function handlerChangeDiscountOneStep(store) {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData


    if(quizState.loading) return;
    if(!quizData.info.discount || !quizData.info.discount.value  || ['increasing', 'increasingPercent'].indexOf(quizData.info.discount.type) == -1) return;

    const discountOneStep = Math.round(quizData.info.discount.value  / (quizData.questions.length + 1))
    if (discountOneStep != quizState.discountOneStep)
        store.dispatch(setQuizStateIem('discountOneStep', discountOneStep))
}





