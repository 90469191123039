import React from "react";

export default class Loading extends React.Component {
    
    render() {
        return (<div className="loading-overlay is-active">
            <div className="loading-background"></div>
            <div className="loading-icon"></div>
        </div>)
    }
}