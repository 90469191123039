import {t} from "leadformsquizentities/translate";

export const validations = function (quizData, lang = null) {
    if (!quizData.info.form.button) quizData.info.form.button = t('Получить результаты', lang);
    if (!quizData.info.form.thanksTitle) quizData.info.form.thanksTitle = t('Спасибо!', lang);
    if (!quizData.info.title) quizData.info.title = t('Заголовок квиза', lang);
    if (!quizData.info.startPage.buttonText) quizData.info.startPage.buttonText = t('Пройти тест', lang);
    if(quizData.full) quizData.full = null;
    return quizData;
}

export const validateQuestion = function (question, lang = null) {
    if (!question.title) {
        question.title = t('Заголовок вопроса', lang);
    }
    if (!question.sliderOptions) {
        question.sliderOptions = {
            isMultiple: false,
            minValue: 0,
            maxValue: 100
        }
    }else{
        if(!question.sliderOptions.minValue) question.sliderOptions.minValue = 0;
        if(!question.sliderOptions.maxValue) question.sliderOptions.maxValue = 0;
    }

    if (question.answers) {
        question.answers = question.answers.map((answer) => {
            if (!answer.title) {
                answer.title = `вариант ответа`;
            }
           return answer;
        });
    }
    return question;
}



