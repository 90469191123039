import 'whatwg-fetch';
import {config} from '../_services';

export const quizzesService = {
    getQuiz,
    sendClientForm,
    quizIsLoad,
    findQuizByDomain,
    getFlats
};

function findQuizByDomain(domain: string) {
    const requestOptions = {
        method: 'GET'
    };
    return requestApi(`get_quiz_by_domain/${domain}`, requestOptions);
}

function getQuiz(quizId: number) {
    const requestOptions = {
        method: 'GET'
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}`, requestOptions).then(handleResponse)
}

function getFlats(answers: any) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({answers: answers}),
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(`${config.apiUrl}/flats`, requestOptions).then(handleResponse)
}


function quizIsLoad(quizId: number) {

    function getParameterByName(name: string) {
        const url = document.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const data = {
        url: null !== getParameterByName('href') ? getParameterByName('href') : window.location.href
    };
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    };

    return fetch(`${config.apiUrl}/quizzes/${quizId}/load`, requestOptions).then(handleResponse)
}

function sendClientForm(data: any) {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {'Content-Type': 'application/json'}
    };
    return fetch(`${config.apiUrl}/leads`, requestOptions).then(handleResponse);
}


function handleResponse(response: Response) {
    return response.json().then(data => {
        if (!response.ok) {
            const error = (data && data.error && data.error.message) || 'Неизвестная ошибка';
            return Promise.reject(error);
        }
        return data;
    }, errorData => {
        return Promise.reject('Неизвестная ошибка');
    });
}


export function requestApi(path: string, requestOptions: object) {
    return fetch(`${config.apiUrl}/${path}`, requestOptions).then(handleResponse).catch(
        error => {
            return Promise.reject(typeof error === 'string' ? error : 'Неизвестная ошибка');
        }
    )
}



