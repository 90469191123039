import {quizzesService} from '../_api';
import {initQuizSateAction, setQuizStateIem} from '../_reducers/quizState.reducer';
import {setFlatsDataItems, setFlatsDataItem} from '../_reducers/flatsData.reducer';
import {initQuizDataAction} from '../_reducers/quizData.reducer';
import {checkConditionIsDone} from "../_services/branchQuestionService";
import {isPossiblePhoneNumber} from "react-phone-number-input";
import {store} from "../_services";
import {ThunkAction,} from 'redux-thunk'
import {Action} from 'redux';
import {RootState} from "../_reducers";
import {QuizStateInterface} from "leadformsquizentities/QuizStateInterface";
import {t} from "leadformsquizentities/translate";
import {ContactType} from 'leadformsquizentities/Contacts/Contact';
import {ResultInterface} from 'leadformsquizentities/Result';
import {getQuestionById} from "../_services/questionService";
import {getParameterByName} from "../_services/urlHelper";
import urlParse from 'url-parse';
import {afterLoadQuiz, afterSendForm, afterNextQuestion, afterStartQuiz} from "../_services/analytic";

export {setQuizStateIem, initQuizSateAction} from '../_reducers/quizState.reducer';
export {setFlatsDataItems, setFlatsDataItem} from '../_reducers/flatsData.reducer';

export const nextStepIsLoading = (): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    dispatch(setQuizStateIem('changeStepLoading', true))
}

export const quizStatePrevStep = (): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    const state = store.getState();
    const quizState = state.quizState;
    const quizData = state.quizData;
    const flatsData = state.flatsData;
    const clientAnswers = quizState.clientData.answers;

    dispatch(setQuizStateIem('changeStepLoading', false));

    switch (quizState.type) {
        case 'start':

            break;
        case 'question':
            let value = null;
            for (let keyQuestion = quizData.questions.length; keyQuestion >= 0; keyQuestion--) {
                const question = quizData.questions[keyQuestion];
                if (quizState.activeKeyItem > keyQuestion) {
                    if (question.displayConditions) {
                        if (checkConditionIsDone(question, clientAnswers)) {
                            value = keyQuestion;
                            break;
                        }
                    } else {
                        value = keyQuestion;
                        break;
                    }
                }
            }

            if (null !== value) {
                dispatch(setQuizStateIem('activeKeyItem', value))
                dispatch(setQuizStateIem('serialActiveKeyItem', quizState.serialActiveKeyItem -1))
            }else if (value === null && quizData.info.startPage.enabled) {
                dispatch(setQuizStateIem('type', 'start'))
            }
            break;
        case 'contacts':
            if (quizData.isForDeveloper || window.location.pathname.match('^/developer/(.+)$') !== null) {
               if (flatsData.items.length > 0) {
                       dispatch(setQuizStateIem('type', 'results'))
               } else {
                    dispatch(setQuizStateIem('type', 'question'))
               }

            } else {
                    if(quizData.info.results && quizData.info.results.enabled && quizData.info.results.afterQuestion && quizState.enabledResultIndexes.length){
                              dispatch(setQuizStateIem('type', 'results'))
                    } else {
                            dispatch(setQuizStateIem('type', 'question'))
                    }
            }


            break;
        case 'results':
            if(!quizData.info.results.afterQuestion) {
                if(!quizData.info.form.disabled){
                    dispatch(setQuizStateIem('type', 'contacts'))
                } else {
                dispatch(setQuizStateIem('type', 'question'))
                }
            } else {
                dispatch(setQuizStateIem('type', 'question'))
            }

            break;
        case 'finish':
            if(quizData.info.results && quizData.info.results.enabled && !quizData.info.results.afterQuestion && quizState.enabledResultIndexes.length){
                dispatch(setQuizStateIem('type', 'results'))
            } else if(!quizData.info.form.disabled){
                dispatch(setQuizStateIem('type', 'contacts'))
            } else if(quizData.info.results && quizData.info.results.enabled && quizData.info.results.afterQuestion && quizState.enabledResultIndexes.length){
                dispatch(setQuizStateIem('type', 'results'))
            } else {
                dispatch(setQuizStateIem('type', 'question'))
            }

            break;
    }


}

export const quizStateNextStep = (): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData
    const clientAnswers = quizState.clientData.answers


    dispatch(setQuizStateIem('changeStepLoading', false));

    switch (quizState.type) {
        case 'start':
            afterStartQuiz(quizData);
            dispatch(setQuizStateIem('type', 'question'));
            break;
        case 'question':
            afterNextQuestion(quizData, quizState.serialActiveKeyItem);

            let value = null;
            for (let keyQuestion = 0; keyQuestion < quizData.questions.length; keyQuestion++) {
                const question = quizData.questions[keyQuestion];
                if (quizState.activeKeyItem < keyQuestion) {
                    if (question.displayConditions) {
                        if (checkConditionIsDone(question, clientAnswers)) {
                            value = keyQuestion;
                            break;
                        }
                    } else {
                        value = keyQuestion;
                        break;
                    }
                }
            }

            let enabledResultIndexes: any[] = [];
            //запишем в стэйт результаты после ответа на последний вопрос
            if (
                !(quizData.isForDeveloper || window.location.pathname.match('^/developer/(.+)$') !== null) &&
                 (value === null && quizData.info.results && quizData.info.results.enabled)
            ) {
                quizData.info.results.items.forEach((item: ResultInterface) => {
                    if (item as any['displayConditions'] ) {
                        if (checkConditionIsDone(item, clientAnswers)) {
                            enabledResultIndexes.push(item.key)
                        }
                    } else {
                        enabledResultIndexes.push(item.key)
                    }

                })
                dispatch(setQuizStateIem('enabledResultIndexes', enabledResultIndexes))
                if (enabledResultIndexes.length === 1) {
                    dispatch(setQuizStateIem('activeKeyResult', enabledResultIndexes[0]))
                }
            }

            if (null !== value) {
                dispatch(setQuizStateIem('activeKeyItem', value))
                dispatch(setQuizStateIem('serialActiveKeyItem', quizState.serialActiveKeyItem + 1))
            } else if(quizData.info.results && quizData.info.results.enabled && quizData.info.results.afterQuestion && (enabledResultIndexes.length || quizData.isForDeveloper || window.location.pathname.match('^/developer/(.+)$') !== null)){
                if (quizData.isForDeveloper || window.location.pathname.match('^/developer/(.+)$') !== null) {
                    dispatch(setQuizStateIem('loading', true))
                     let answers = []

                        for (let key in quizState.clientData.answers){
                            if(quizState.clientData.answers[key]){
                                const question = getQuestionById(key);
                                answers.push({
                                    q: question.title,
                                    questionId: question.id,
                                    a: quizState.clientData.answers[key]
                                })
                            }
                        }
                     quizzesService.getFlats(answers).then(flats => {
                         dispatch(setFlatsDataItems(flats));
                         if(flats.length > 0) {
                            dispatch(setQuizStateIem('type', 'results'))
                         } else {
                            dispatch(setQuizStateIem('type', 'contacts'))
                         }

                         dispatch(setQuizStateIem('loading', false))
                     });
                } else{
                    dispatch(setQuizStateIem('type', 'results'))
                }
            } else if(!quizData.info.form.disabled){
                dispatch(setQuizStateIem('type', 'contacts'))
            } else if(quizData.info.results && quizData.info.results.enabled && !quizData.info.results.afterQuestion && (enabledResultIndexes.length || true === quizData.isForDeveloper)){

                dispatch(setQuizStateIem('type', 'results'))
            }else {
                dispatch(handlerSendClientForm(() =>  dispatch(setQuizStateIem('type', 'finish'))));
            }
            break;
        case 'contacts':
            if(quizData.info.results && quizData.info.results.enabled && !quizData.info.results.afterQuestion && quizState.enabledResultIndexes.length){
                dispatch(setQuizStateIem('type', 'results'))
            } else {
                dispatch(handlerSendClientForm(() =>  dispatch(setQuizStateIem('type', 'finish'))));
            }
            break;
        case 'results':
            if(quizData.info.results.afterQuestion && !quizData.info.form.disabled){
                dispatch(setQuizStateIem('type', 'contacts'))
            } else {
                dispatch(handlerSendClientForm(() =>  dispatch(setQuizStateIem('type', 'finish'))));
            }
            break;
        case 'finish':
            break;
    }


}

export const changeContactValue = (keyContact: ContactType, valueContact: any): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    const state = store.getState()
    const quizState = state.quizState;
    const quizData = state.quizData;
    let newClientData = quizState.clientData

    newClientData.contacts[keyContact] = valueContact;
    dispatch(setQuizStateIem('clientData', newClientData));

    const errorText = quizState.contactsValidate[keyContact];

    if (null !== errorText) {
        dispatch(changeContactErrorMessage(keyContact, null));
    }

    let isPossibleSendClientForm = true;

    Object.keys(quizData.info.form.contacts).forEach((contactKey) => {
        const contactData = quizData.info.form.contacts[contactKey];

        if (contactData && undefined !== contactData.enabled && true === contactData.enabled) {
            const contactValue = quizState.clientData.contacts[contactKey];
            let phoneMaskType;
            let phoneMaskRegexp;

            if (undefined !== contactData.mask && true === contactData.mask.enabled) {
                phoneMaskType = contactData.mask.type;
                if ('mask' === contactData.mask.type && typeof contactData.mask.regexp === 'string' && contactData.mask.regexp.length >= 1) {
                    phoneMaskRegexp = contactData.mask.regexp;
                }
            }

            if (null !== validate((contactKey as any), contactData.required, contactValue, phoneMaskType, phoneMaskRegexp)) {
                isPossibleSendClientForm = false;
            }
        }

    })

    if (isPossibleSendClientForm !== quizState.isPossibleSendClientForm) {
        dispatch(setQuizStateIem('isPossibleSendClientForm', isPossibleSendClientForm))
    }

}

const changeContactErrorMessage = (keyContact: ContactType, errorMessage: any): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    const state = store.getState()
    const quizState = state.quizState
    let contactsValidate = quizState.contactsValidate
    contactsValidate[keyContact] = errorMessage
    dispatch(setQuizStateIem('contactsValidate', contactsValidate))
}

export const validateContactValue = (keyContact: ContactType): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {

    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData;

    let phoneMaskType;
    let phoneMaskRegexp;

    const contactData = quizData.info.form.contacts[keyContact];
    const contactValue = quizState.clientData.contacts[keyContact];

    if (undefined !== contactData.mask && true === contactData.mask.enabled) {
        phoneMaskType = contactData.mask.type;
        if ('mask' === contactData.mask.type && typeof contactData.mask.regexp === 'string' && contactData.mask.regexp.length >= 1) {
            phoneMaskRegexp = contactData.mask.regexp;
        }
    }

    const errorText = validate(keyContact, contactData.required, contactValue, phoneMaskType, phoneMaskRegexp);
    if (errorText !== keyContact) {
        dispatch(changeContactErrorMessage(keyContact, errorText));
    }
}


const validate = (contactKey: ContactType, isRequired: boolean, value: any, phoneMaskType: any, phoneMaskRegexp: any) => {
    if (undefined === value || 0 === value.length) {
        if (true === isRequired || undefined === isRequired) {
            return t('Поле обязательно для заполнения');
        }
    } else {
        switch (contactKey) {
            case "email":
                if (false === /\S+@\S+\.\S+/.test(value)) {
                    return t('Неправильный формат email');
                }
                break;
            case "phone":
                if (undefined !== phoneMaskType) {
                    if ('mask' === phoneMaskType && undefined !== phoneMaskRegexp && 0 < phoneMaskRegexp.length) {
                        const phonePattern = new RegExp(phoneMaskRegexp.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&").replace(/1/g, '\\d'));
                        if (false === phonePattern.test(value)) {
                            return t('Неправильный формат номера');
                        }
                    } else if ('country' === phoneMaskType) {
                        if (false === isPossiblePhoneNumber(value)) {
                            return t('Неправильный формат номера');
                        }
                    }
                }
        }
    }

    return null
}

export const changeAnswerClient = (questionId: number, answerKey: number|string, value: any): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData
    let newClientData = {
        answers: quizState.clientData.answers,
        contacts: quizState.clientData.contacts
    }


    let bufAnswers = (undefined !== newClientData.answers[questionId]) ? newClientData.answers[questionId] : {}
    const questionActive = quizData.questions[quizState.activeKeyItem]
    
    if (!questionActive.multySelect && -1 !== ['answers', 'images', 'select'].indexOf(questionActive.type)) bufAnswers = {}
    bufAnswers[answerKey] = value;
    let newAnswers = {}
    for (let index in bufAnswers) {
        if (undefined !== bufAnswers[index] && null !== bufAnswers[index] && '' !== bufAnswers[index])
            (newAnswers as any)[index] = bufAnswers[index]
    }
    newClientData.answers[questionId] = newAnswers
    dispatch(setQuizStateIem('clientData', newClientData))

    if(value && questionActive.multySelect === false && answerKey !== 'other' && false === quizState.changeStepLoading && -1 !== ['answers', 'images'].indexOf(questionActive.type)) {
       if (quizData.notAutoNextStep) {
           return;
       }
        dispatch(nextStepIsLoading());
        setTimeout(() => {
            dispatch(quizStateNextStep());
        }, 700);
    }
}

const init = (quizId: number): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    quizzesService.getQuiz(quizId)
        .then(
            quizData => {

                if(quizData && quizData.info){
                    if(quizData.info.startPage.pageTitle) document.title = quizData.info.startPage.pageTitle
                    if(quizData.info.startPage.favicon) {
                        (window.document.getElementById('shortcut') as any).setAttribute('href', `//${quizData.info.startPage.favicon.server}/estorage-w100-h100-cscale/${quizData.info.startPage.favicon.url}`)
                    }
                }

                afterLoadQuiz(quizData);

                dispatch(initQuizDataAction(quizData))
                dispatch(initQuizSateAction(getQuizState(quizData)))
                quizzesService.quizIsLoad(quizId);
                if (quizData.blocked) {
                    dispatch(setErrorMessage('Квиз заблокирован'))
                }
            },
            error => setErrorMessage(error)
        );
}
export const setErrorMessage = (errorMessage: string): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    store.dispatch(setQuizStateIem('errorMessage', errorMessage))
}

const checkIsPossibleSendClientForm = (contacts: { enabled: boolean, required?: boolean }[]) => {
    for (let [key, contact] of Object.entries(contacts)) {
        if (true === contact.enabled && (true === contact.required || undefined === contact.required)) {
            return false;
        }
    }

    return true;
};

function getQuizState(quizData: any): QuizStateInterface {

    let clientAnswers = {};

    quizData.questions.forEach((question: any) => {
        if ('slider' === question.type) {
            const maxValue = +question.sliderOptions.maxValue;
            const minValue = +question.sliderOptions.minValue;
            const middle = (maxValue + minValue)/2;

            if(true === question.sliderOptions.isMultiple) {
                (clientAnswers as any)[question.id] =  question.sliderOptions.fromMinToMaxValue ? {first: minValue, second: maxValue} : {first: Math.round((middle + minValue)/2), second: Math.round((middle + maxValue)/2)};
            } else {
                (clientAnswers as any)[question.id] = {first: Math.round(middle)};
            }
        }
    })
    return {
        loading: false,
        errorMessage: null,
        changeStepLoading: false,
        sendedClientForm: false,
        isPossibleSendClientForm: checkIsPossibleSendClientForm(quizData.info.form.contacts),
        clientData: {
            answers: clientAnswers,
            contacts: {}
        },
        contactsValidate: {
            'phone': null,
            'email': null,
            'name': null
        },
        type: quizData.info.startPage.enabled ? 'start' : 'question',
        activeKeyItem: 0,
        serialActiveKeyItem: 1,
        form: {
            buttonPrevDisabled: true,
            buttonNextDisabled: quizData.questions.length > 1 ? false : true
        },
        discountOneStep: false,
        discountSeek: false,
        discount: (quizData.info.discount && quizData.info.discount.type === 'melting') ? quizData.info.discount.value : false,
        additionalIsOpen: false,
        privacyAgreement: true,
        activeKeyResult: undefined,
        enabledResultIndexes: []
    }
}

export const quizStateActions = {
    init,
    changeContactValue,
    changeAnswerClient
};

export const handlerSendClientForm = (callback: Function = () => {}, dontSend:boolean = false): ThunkAction<void, RootState, null, Action<string>> => async dispatch => {
    store.dispatch(setQuizStateIem('loading', true))
    const state = store.getState()
    const quizState = state.quizState
    const quizData = state.quizData
    const flatsData = state.flatsData

    if(dontSend){
        callback();
        store.dispatch(setQuizStateIem('loading', false))
        store.dispatch(setQuizStateIem('sendedClientForm', true))
    }


    let answers = []

    for (let key in quizState.clientData.answers){
        if(quizState.clientData.answers[key]){
            const question = getQuestionById(key);
            answers.push({
                q: question.title,
                questionId: question.id,
                a: quizState.clientData.answers[key]
            })
        }
    }


    const href = getParameterByName('href') ? getParameterByName('href') : window.location.href;
    const cookiesString = getParameterByName('cookies') ? getParameterByName('cookies') : document.cookie;
    const urlParsed =  urlParse(href);
    const query: any =  urlParsed.query;

    let selectedResult: any = null;

    if (quizData.info.results && quizState.activeKeyResult) {
        quizData.info.results.items.forEach((item: ResultInterface) => {
            if(quizState.activeKeyResult === item.key) selectedResult = item;
        });
    }

    if (null === selectedResult && flatsData.items && flatsData.items.length) {
        flatsData.items.forEach((item: any) => {
            if (quizState.activeKeyResult === item.externalId) {
                let children = [];
                if (item.livingArea) {
                    children.push({name: 'Жилая', value: `${item.livingArea} м2`});
                }
                if (item.kitchenArea) {
                    children.push({name: 'Кухня', value: `${item.kitchenArea} м2`});
                }
                let info: any = [{name: 'Площадь', value: `${item.totalArea} м2`, children: children}];

                info.push({name: 'Комнат', value: `${item.flats}`});
                info.push({name: 'Этаж', value: item.floorNumber});
                info.push({
                    name: 'Стоимость',
                    value: `${parseInt(item.price).toLocaleString('ru-RU')} р`,
                    isColor: true
                });
                selectedResult = {
                    value: item.externalId,
                    title: `Кол-во комнат: ${item.flats}, №${item.flatNumber}`,
                    description: `projectName: ${item.projectName} address: ${item.address}`,
                }
            }
        })
    }

    let data = {
        quizId: quizData.id,
        answers2: answers,
        contacts: quizState.clientData.contacts,
        extra:{
            href: href,
            ref: getParameterByName('ref') ? getParameterByName('ref') : document.referrer,
            utm:  getUtms(query),
            cookies:  getCookies(cookiesString)
        },
        result: selectedResult
    }

    function getCookies(cookieString: string) {
        function getCookie(name: string, cookieString: string) {
            var matches = cookieString.match(new RegExp(
                "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        function getCookieComagic(cookieString: string) {
            var matches = cookieString.match(new RegExp(
                "(?:^|; )_comagic_id[^=]*=([^;]*)"
            ));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        }

        var t = ["roistat_visit", "roistat_marker", "roistat_marker_old", "roistat_call_tracking", "roistat_param1", "roistat_param2", "roistat_emailtracking_email", "lp_tracker_id", "lptracker_visitor_id", "lptracker_view_id", "_ga", "_userid", '_ym_uid'];
        var cookies: any = {};
        for (var i = 0; i < t.length; i++) {
            var e = t[i];
            let cookieItem = getCookie(e, cookieString);
            if (undefined !== cookieItem) {
                cookies[e] = cookieItem;
            }
        }

        let cookieItem = getCookieComagic(cookieString);
        if (undefined !== cookieItem) {
            cookies['_comagic_id'] = cookieItem;
        }

        return cookies;
    }

    function getUtms(query: string) {
        var vars = query.substring(1).split("&");
        var query_string: any = {};
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            var key = pair[0];
            var value = pair[1];
            if ("utm_" == key.substr(0, 4) || key == 'admitad_uid'|| key == 'tagtag_uid') {
                if (typeof query_string[key] == "undefined") {
                    query_string[key] = value;
                } else if (typeof query_string[key] == "string") {
                    var arr = [query_string[key], value];
                    query_string[key] = arr;
                } else {
                    query_string[key].push(value);
                }
            }

        }
        return query_string;
    }



    if(quizState.discount && quizData.info.discount){
        (data.extra as any)['discount'] = (quizState.discount) ? `${quizState.discount}${(quizData.info.discount.type == 'increasingPercent') ? '%' : (quizData.info.discount.currency ? quizData.info.discount.currency : "₽")}` : false
    }


    quizzesService.sendClientForm(data)
        .then(
            function () {
                afterSendForm(quizData)

                store.dispatch(setQuizStateIem('loading', false))
                store.dispatch(setQuizStateIem('sendedClientForm', true))
                callback();
            },
            function () {
                store.dispatch(setQuizStateIem('errorMessage', 'errorMessage'))
                store.dispatch(setQuizStateIem('loading', false))
                store.dispatch(setQuizStateIem('sendedClientForm', true))
            }
        );



}

