
const defaultState: any = {
    items: null,
    item: null
};

export const SET_FLATS_DATA_LIST = 'SET_FLATS_DATA_LIST';
export const SET_FLATS_DATA_ITEM = 'SET_FLATS_DATA_ITEM';

export const flatsDataReducer = (state: any = defaultState, action: FlatsDataActionTypes): any => {
    switch (action.type) {
        case SET_FLATS_DATA_LIST:
           let newState1: any = {...state};
           (newState1 as any).items = action.items as any;
           return newState1;
        case SET_FLATS_DATA_ITEM:
            let newState2: any = {...state};
            (newState2 as any).item = action.item as any;
            return newState2;
        default:
            return state;
    }
};

interface SetFlatsDataItemActionInterface {
    type: typeof SET_FLATS_DATA_ITEM,
    item: any
}

export function setFlatsDataItem(item: any): SetFlatsDataItemActionInterface {
    return {
        type: SET_FLATS_DATA_ITEM,
        item: item
    }
}

interface SetFlatsDataItemsActionInterface {
    type: typeof SET_FLATS_DATA_LIST,
    items: any
}


export function setFlatsDataItems(items: any): SetFlatsDataItemsActionInterface {
    return {
        type: SET_FLATS_DATA_LIST,
        items: items
    }
}

export type FlatsDataActionTypes = SetFlatsDataItemsActionInterface|SetFlatsDataItemActionInterface;







