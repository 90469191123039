//prod
export const config = {
    apiUrl: 'https://api.leadforms.ru/v1',
    quizHosts: ['dev-quiz.leadforms.ru', 'quiz.leadforms.ru', 'localhost:3000']
};

// //dev
// export const config = {
//     apiUrl: 'https://dev-api.leadforms.ru/v1',
//     quizHosts: ['dev-quiz.leadforms.ru', 'localhost:3000']
// };
